import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";

const NewsTopbar = dynamic(() => import("../../newsCard/newsTopbar"));

const LiveElectionCard = () => {
  const { liveShowpostData } = useSelector(({ PostReducer }) => PostReducer);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getLiveShowPost());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {liveShowpostData?.entities &&
        liveShowpostData?.entities[0]?.isElection && (
          <>
            {/* <div className="news-card-box">
              <NewsTopbar
                newsEn={"Live Updates"}
                newsHn={"लाइव अपडेट"}
                newsIcon={""}
                newsBgclassName={"bar-bg-1"}
              />
            </div> */}
            {/* <div className="live-blog-iframed">
              <iframe
                className="live-blog-iframe lb24-iframe"
                name="lb24"
                //frameBorder="0"
                height="960px"
                loading="lazy"
                width="100%"
                //scrolling="auto"
                src="//v.24liveblog.com/iframe/?id=3313991634351676082"
                // style={{ maxHeight: "320px" }}
              ></iframe>
            </div> */}
          </>
        )}
    </>
  );
};

export default LiveElectionCard;
